<template>
  <v-app>
    <component  v-if="canDisplayContent" :is="layout">
      <router-view :layout.sync="layout" />
    </component>
      <dashboard-layout v-else class="pa-4">
        <v-alert dense outlined type="error">
          {{ $t("panel_available_only_in_md_plus_devices") }}
        </v-alert>
      </dashboard-layout>
  </v-app>
</template>
z

<script>
import DashboardLayout from "./layouts/DashboardLayout.vue";
export default {
  components: { DashboardLayout },
  name: "App",
  computed: {
    canDisplayContent() {
      const grantedRoutes = [
        "login",
        "recover-account",
        "reset-password",
        "trainings.list",
        "training.employee.show",
      ];
      return (
        this.$vuetify.breakpoint.smAndUp ||
        grantedRoutes.includes(this.$route.name)
      );
    },
  },
  data: () => ({
    layout: `div`,
  }),
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");

html,
body {
  font-family: "Roboto", sans-serif;
}
#app {
  font-family: "Roboto", sans-serif;
}
</style>

<style lang="scss">
#app {
  background-color: #fff;
}

.limit-text-1 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}
</style>
